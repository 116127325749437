<template>
  <div>
    <a-modal v-model:visible="visible" title="新增药对" @ok="handleOk" okText="新增" cancelText="取消" :afterClose="closeModal" width="30vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="名称">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="描述">
          <a-input v-model:value="data.description" />
        </a-form-item>
        <a-form-item label="来源">
          <a-input v-model:value="data.source" />
        </a-form-item>
        <a-table class="ant-table-striped" :columns="columns" :data-source="data.items" rowKey="id" :pagination="false">
          <template v-for="col in ['quantity']" #[col]="{ text, record }" :key="col">
            <div>
              <!-- <div>{{record.id}}</div> -->
              <a-input-number v-model:value="record[col]" style="margin: -5px 0" />
            </div>
          </template>
          <template v-for="col in ['description']" #[col]="{ text, record }" :key="col">
            <div>
              <!-- <div>{{record.id}}</div> -->
              <a-input v-model:value="record[col]" style="margin: -5px 0" />
            </div>
          </template>
        </a-table>
        <a-form-item label="药品" style="margin-top:20px">
          <!-- <a-select v-model:value="prescription" @search="filterNameInitial" :filterOption="filter" showSearch @change="handleChange" style="width: 201px;margin-right:10px">
            <a-select-option v-for="item in medicineList" :value="item.id">{{ item.name }}</a-select-option>
          </a-select> -->
          <medicineSelector v-model="medicine" />
          <a-button @click="addToTable()">添加</a-button>
          
        </a-form-item>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import { Add } from "/src/api/prescription.js";
import medicineSelector from '/src/components/selector/medicineSelector.vue';
export default {
  components: {
    medicineSelector
  },
  data() {
    return {
      visible: this.modelValue,
      medicineFilter: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "30%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "用量",
          key: "quantity",
          dataIndex: "quantity",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "quantity"
          }
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          // ellipsis: true,
          width: "35%",
          slots: {
            customRender: "description"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      medicine: "",
      data: {
        name: undefined,
        description: undefined,
        source: undefined,
        quantity: undefined,
        items: [],
      },
      selectedRows: {},
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      editableData: reactive({}),
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    medicineList() {
      const list = this.$store.state.medicineList;
      if (!this.medicineFilter) {
        return list.slice(0, 200);
      }
      return list.filter(item => item.name?.toLowerCase().includes(this.medicineFilter) || item.nameInitial?.toLowerCase().includes(this.medicineFilter)).slice(0, 200);
    }
  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      } else {
        this.$message.error("删除失败", function () { });
      }

    },
    async handleOk() {
      let _this = this;
      let obj = { ...this.data, type: "中药处方", category: "药对" }
      obj.items.forEach(item => {
        // 使用delete操作符删除id属性
        delete item.id;
      });
      let res = await Add(obj)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          description: undefined,
          source: undefined,
          items: [],
        }

        this.$store.dispatch('getPrescriptionList')

        this.$emit('getData', res.data);
      }

    },
    addToTable() {
      let item = this.$store.state.medicineList.find(item => item.name == this.medicine)
      if (this.data.items.find(element => element.itemName == this.medicine)) {
        this.$message.info("已添加至列表", function () { });
        return
      }
      console.log(this.medicine);
      console.log(item);
      let id = item.id + 999999999
      let obj = { itemName: item.name, medicineId: item.id, id, quantity: undefined, description: undefined, type: "new" }
      this.data.items.push({ ...obj })
      this.editableData[id] = { ...obj }

      // console.log(this.modelInput.items)
      this.medicine = ""
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
